<script setup lang="ts">
import { shallowRef } from 'vue'
import { VideoPlayer as VideojsPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

interface PlayerOptions {
  url: string
  poster?: string
  fluid?: boolean
  autoplay?: boolean
  aspectRatio?: string
}

// https://github.com/surmon-china/videojs-player

type EventType =
  | 'ready'
  | 'play'
  | 'pause'
  | 'ended'
  | 'loadeddata'
  | 'waiting'
  | 'playing'
  | 'canplay'
  | 'canplaythrough'
  | 'timeupdate'

defineOptions({
  name: 'VideojsPlayer'
})

withDefaults(defineProps<PlayerOptions>(), {
  autoplay: true,
  fluid: false
})

const emit = defineEmits([
  'ready',
  'play',
  'pause',
  'ended',
  'loadeddata',
  'waiting',
  'playing',
  'canplay',
  'canplaythrough',
  'timeupdate'
])

const player = shallowRef()

const options = {
  controls: true
}
function handleEvent(name: EventType, e: any) {
  emit(name, e)
}
const playHandle = () => {
  console.log('player', player)
  // player.value.play();
}
setTimeout(() => {
  console.log('player', player)
}, 2000)
defineExpose({ playHandle })
</script>

<template>
  <div>
    <div class="b-player-container">
      <VideojsPlayer
        ref="player"
        class="vjs-tech b-player"
        :src="url"
        :poster="poster"
        controls
        :volume="0.6"
        :fluid="fluid"
        :autoplay="autoplay"
        :aspect-ratio="aspectRatio"
        :options="options"
        :playback-rates="[2, 1.5, 1, 0.7]"
        @ready="handleEvent('ready', $event)"
        @play="handleEvent('play', $event)"
        @pause="handleEvent('pause', $event)"
        @ended="handleEvent('ended', $event)"
        @loadeddata="handleEvent('loadeddata', $event)"
        @waiting="handleEvent('waiting', $event)"
        @playing="handleEvent('playing', $event)"
        @canplay="handleEvent('canplay', $event)"
        @contextmenu="(e: Event) => e.preventDefault()"
      />
    </div>
  </div>
</template>

<style scoped>
.b-player-container {
  position: relative;
  padding-top: 56.25%;
  background: #000;
  height: 0;
  z-index: 0;
}

.b-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
:deep(.video-js .vjs-play-progress) {
  background: #fd5b03;
}
:deep(.video-js .vjs-progress-holder) {
  font-size: 0.6666666667em !important;
}
:deep(.video-js .vjs-play-progress:before) {
  content: '';
  width: 10px;
  height: 10px;
  color: #fd5b03;
  border: 3px solid rgba(253, 91, 3, 0.5);
  border-radius: 50%;
  margin-top: -4px;
  line-height: normal;
  font-size: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
:deep(.video-js .vjs-volume-panel) {
  display: none;
}
:deep(.video-js .vjs-picture-in-picture-control) {
  display: none;
}
:deep(.video-js .vjs-playback-rate) {
  display: none;
}
</style>
